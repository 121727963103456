import { useAppSelector as useSel } from '@root/store'
import { selectHasAccessToContent } from '@store/contents-slice/_selectors'
import { selectUserId } from '@store/user-slice/_selectors'
import { useMemo } from 'react'
import { isEmptyString } from '@utils/strings'

export default function useUserHasAccessToContent(contentId: string) {
  const userHasAccessSelector = useSel(selectHasAccessToContent(contentId))
  const userId = useSel(selectUserId)

  return useMemo(() => {
    return !isEmptyString(userId) && userHasAccessSelector
  }, [userId, userHasAccessSelector])
}
