import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { CheckoutContext, CheckoutContextInterface } from '@providers/checkout-provider/_contexts'

/**
 * A couple of reasons why I have made this provider:
 *
 * 1. To provide a better way to handle flexi-price details and purchase-and-free details.
 * - We previously sent setFlexiPrice down to PurchaseAndFreeDetails and FlexiPriceDetails, which was about 4-5 components deep.
 *
 * 2. To provide a better way to handle the price total.
 * - Now that we provide coupons (which can change the price), we need to be able to update the price total in the UI of the checkout.
 * The idea is now instead of using the contentPrices[0].price, we can use the priceTotal state.
 *
 * 3. To provide a better way to handle the mailing list checkbox.
 *
 */

interface Props {
  children: ReactNode
}

export default function CheckoutProvider({ children }: Readonly<Props>): ReactElement {
  const [flexiPrice, setFlexiPrice] = useState<number>()
  const [totalPrice, setTotalPrice] = useState<number>()
  const [isJoinMailingList, setIsJoinMailingList] = useState(true)

  const checkoutContext: CheckoutContextInterface = {
    flexiPrice,
    totalPrice,
    isJoinMailingList,
    setFlexiPrice,
    setTotalPrice,
    setIsJoinMailingList,
  }

  return <CheckoutContext.Provider value={checkoutContext}>{children}</CheckoutContext.Provider>
}
