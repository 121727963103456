import { selectContentStateForContentById } from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { useEffect } from 'react'
import useUserHasAccessToContent from '@hooks/_content/use-user-has-access-to-content'

// Scenario 1:
// When Firebase is authenticating, user is not logged in.
// The guide button will be buyNow state, and the user can click it and open the modal.
// After Firebase finish authenticating, this video may has been purchased or booked.
// So use this effect to close the modal under this situation.
// Scenario 2:
// After the user checkout with Stripe, take user back to this dialog.
// After the checkout status change to purchased, this effect close the modal.
export function useHideVideoInfoPageDialog(contentId: string, hideDialog: () => void): void {
  const contentState = useSel(selectContentStateForContentById(contentId)) ?? ''
  const userHasAccess = useUserHasAccessToContent(contentId)

  useEffect(() => {
    const contentStatesToHideDialog = ['expired', 'soldOut', 'accessRestricted', 'geoBlocked']
    if (userHasAccess || contentStatesToHideDialog.includes(contentState)) {
      hideDialog()
    }
  }, [hideDialog, contentState, userHasAccess])
}
